import React from 'react';
import { FC, useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import Tab from '@mui/material/Tab';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import EastIcon from '@mui/icons-material/East';
import HelpIcon from '@mui/icons-material/Help';
import ScaleIcon from '@mui/icons-material/Scale';
import DeleteIcon from '@mui/icons-material/Delete';
import NumbersIcon from '@mui/icons-material/Numbers';
import SetMealIcon from '@mui/icons-material/SetMeal';
import SignLanguage from '@mui/icons-material/SignLanguage';
import DescriptionIcon from '@mui/icons-material/Description';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import TakeoutDiningIcon from '@mui/icons-material/TakeoutDining';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import DirectionsBoatFilledIcon from '@mui/icons-material/DirectionsBoatFilled';
import EditIcon from '@mui/icons-material/Edit';
import { DataGrid, GridColDef, GridLogicOperator, GridToolbarContainer } from '@mui/x-data-grid';
import { BaseSingleInputFieldProps, DatePicker, DatePickerProps, DateValidationError, FieldSection, LocalizationProvider, UseDateFieldProps } from '@mui/x-date-pickers';
import { Autocomplete, Badge, Box, Button, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, IconButton, InputAdornment, Modal, Stack, styled, Switch, TextField, Theme, Tooltip, tooltipClasses, TooltipProps, Typography } from '@mui/material';
import Ship from '../../../../Models/Interfaces/Ship';
import Product from '../../../../Models/Interfaces/Product';
import Landing from '../../../../Models/Interfaces/Landing';
import Certificate from '../../../../Models/Interfaces/Certificate';
import LandingSubmit from '../../../../Models/SubmitModels/LandingSubmit';
import CertificateStatuses from '../../../../Constants/CertificateStatuses';
import ReferenceLanding from '../../../../Models/Interfaces/ReferenceLanding';
import LandingErrorModel from '../../../../Models/ErrorModels/LandingErrorModel';
import { addLanding, deleteLanding, editLanding, getConnectedReferenceLandings, getReferenceLandings, getShips } from '../../../../Services/VeidivottordAPIService';
import { AddLandingTitle, Card, CardData, CardLabel, InputRow, LandingBox, LandingDatagridContainer, LandingModalWrapper, LandingWeightInputBox, LandingWorkspace, RowContainer } from './LandingModal.styled';
import LandingEditSubmit from '../../../../Models/SubmitModels/LandingEditSubmit';
import SearchIcon from '@mui/icons-material/Search';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import WarningIcon from '@mui/icons-material/Warning';

interface AddLandingModalProps {
  toggleLandingModal: () => void;
  landingModalOpen: boolean;
  selectedCertificate: Certificate | null;
  selectedProduct: Product | undefined;
  openSnackbar: (statusCode: number) => void;
  setLoadingState: (loadingState: boolean) => void;
  fetchCertificate: (certificateId: number) => void;
  desktopView: boolean;
}

const AddLandingModal: FC<AddLandingModalProps> = (props) => {

  const [referenceLandings, setReferenceLandings] = useState<ReferenceLanding[]>([]);
  const [connectedReferenceLandings, setConnectedReferenceLandings] = useState<ReferenceLanding[]>([]);
  const [ships, setShips] = useState<Ship[]>([]);
  const [selectedReferenceLanding, setSelectedReferenceLanding] = useState<ReferenceLanding | null>();
  const [amountLeftSelectedReferenceLanding, setAmountLeftSelectedReferenceLanding] = useState<number | null>(null);
  const [selectedConnectedReferenceLandingId, setSelectedConnectedReferenceLandingId] = useState<number | null>(null);
  const [selectedShip, setSelectedShip] = useState<Ship | null>();
  const [loadingShips, setLoadingShips] = React.useState(false);
  const [loadingLandings, setLoadingLandings] = React.useState(false);
  const [landingFormData, setLandingFormData] = useState<LandingSubmit | LandingEditSubmit>({} as LandingSubmit | LandingEditSubmit);
  const [filteredLandings, setFilteredLandings] = useState<Landing[]>([]);
  const [landingWeightModalOpen, setLandingWeightModalOpen] = useState(false);
  const [checked, setChecked] = React.useState(true);
  const [tabValue, setTabValue] = React.useState('1');
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  const [landingId, setLandingId] = React.useState<number | undefined>();
  const [errorModel, setErrorModel] = useState<LandingErrorModel>({} as LandingErrorModel);
  const [dateFilterValue, setDateFilterValue] = React.useState<Dayjs | null>(dayjs().subtract(12, 'month'));
  const [singleDateFilterValue, setSingleDateFilterValue] = React.useState<Dayjs | null>(null);
  let multipleProductsConnectedToLanding = amountLeftSelectedReferenceLanding !== selectedReferenceLanding?.landedAmount;

  useEffect(() => {
    if (props?.selectedCertificate?.fromDotNetAPI === false) {
      setFilteredLandings(props?.selectedCertificate?.landings ? props?.selectedCertificate?.landings.filter(landing => landing.fishType.id === props.selectedProduct?.fishType.id) : []);
    } else {
      setFilteredLandings(props?.selectedCertificate?.landings ? props?.selectedCertificate?.landings.filter(landing => landing.productId === props.selectedProduct?.id) : []);
    }
    handleGetConnectedReferenceLandings();
    handleChange("netWeightFromLanding", null);
  }, [props.selectedCertificate, props.selectedProduct]);

  useEffect(() => {
    setSelectedShip(null);
    setSelectedReferenceLanding(null);
    setReferenceLandings([]);
    setSingleDateFilterValue(null);
    setChecked(false);
    if (props.selectedCertificate?.status?.id === CertificateStatuses.CONFIRMED || props.selectedCertificate?.status?.id === CertificateStatuses.VOID || props.selectedCertificate?.fromDotNetAPI === false) {
      setTabValue('2');
    } else {
      setTabValue('1');
    }
  }, [props.landingModalOpen]);

  const handleSubmitLanding = async () => {
    toggleLandingWeightModal();
    const response = await addLanding(props.setLoadingState, landingFormData, props.selectedProduct?.id);
    props.fetchCertificate(props.selectedCertificate?.id ? props.selectedCertificate?.id : 0);
    props.openSnackbar(response.status);
  };

  const handleEditLanding = async () => {
    toggleLandingWeightModal();
    const response = await editLanding(props.setLoadingState, landingFormData, props.selectedProduct?.id);
    props.fetchCertificate(props.selectedCertificate?.id ? props.selectedCertificate?.id : 0);
    props.openSnackbar(response.status);
  };

  const handleDeleteLanding = async (landingId?: number) => {
    toggleDeleteDialog();
    const response = await deleteLanding(props.setLoadingState, landingId);
    props.fetchCertificate(props.selectedCertificate?.id ? props.selectedCertificate?.id : 0);
    props.openSnackbar(response.status);
  };

  const handleTabChange = (e: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  const toggleDeleteDialog = (landingId?: number) => {
    setDeleteDialogOpen(!deleteDialogOpen);
    setLandingId(landingId);
  };

  const handleChange = (name: string, value: string | number | Date | null | undefined) => {
    setLandingFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const handleShipSearch = async (shipInputValue: string) => {
    if (shipInputValue.length >= 2) {
      setLoadingShips(true);
      setReferenceLandings([]);
      setSelectedReferenceLanding(null);
      const shipResult = await getShips(shipInputValue);
      setShips(shipResult);
      setLoadingShips(false);
    }
  }

  const handleReferenceLandingChange = (referenceLanding: ReferenceLanding | null) => {
    setSelectedReferenceLanding(referenceLanding);

    // Calculate how many kgs are left of the selected reference landing, of the same fish type.
    const usedAmountTotalSum = props.selectedCertificate?.landings.filter(landing => landing.landingId === referenceLanding?.landingId).filter(landing => landing.fishType.id === props.selectedProduct?.fishType?.id).map(landing => landing.usedAmount).reduce((a, b) => a + b, 0);
    if (usedAmountTotalSum !== undefined && referenceLanding?.landedAmount !== undefined) {

      // If user is editing, we will find the landing here.
      const editingLanding = filteredLandings.find(landing => landing.landingId === referenceLanding?.landingId);

      // Editing landing undefined, so user is adding a landing.
      if (editingLanding === undefined) {
        setAmountLeftSelectedReferenceLanding(referenceLanding.landedAmount - usedAmountTotalSum === 0 ? 0 : referenceLanding.landedAmount - usedAmountTotalSum);
      } else {
        // If the user is editing a landing connection, the amount left should be the landed amount of the reference landing minus the used amount of the landing connection.
        if (editingLanding) {
          setAmountLeftSelectedReferenceLanding(referenceLanding.landedAmount - usedAmountTotalSum + editingLanding.usedAmount);
        }
      }
      handleChange('landingId', referenceLanding?.landingId);
    }
  }

  const handleShipChange = async (ship: Ship | null, fromDate: string) => {
    setSelectedShip(ship);
    handleChange('shipRegistrationId', ship?.registrationId.toString());

    if (ship) {
      setLoadingLandings(true);
      const response = await getReferenceLandings(props.selectedProduct?.id, ship?.registrationId.toString(), fromDate);
      setReferenceLandings(response);
      setLoadingLandings(false);
    } else {
      setShips([]);
      setReferenceLandings([]);
    }
  }

  const handleGetConnectedReferenceLandings = async () => {
    if (props.selectedProduct?.id) {
      const response = await getConnectedReferenceLandings(props.selectedProduct?.id);
      setConnectedReferenceLandings(response);
    }
  }

  const toggleLandingWeightModal = (landingConnectionId?: number) => {
    setSelectedConnectedReferenceLandingId(landingConnectionId ? landingConnectionId : null);

    // Edit mode.
    if (landingConnectionId) {
      const clickedLanding = props.selectedCertificate?.landings.find(referenceLanding => referenceLanding.id === landingConnectionId);
      const referenceLanding = connectedReferenceLandings.find(referenceLanding => referenceLanding.landingId === clickedLanding?.landingId);
      handleReferenceLandingChange(referenceLanding ? referenceLanding : null);
      handleChange('connectedLandingId', landingConnectionId);
    }

    setErrorModel({} as LandingErrorModel);
    setLandingWeightModalOpen(!landingWeightModalOpen);
  }

  const isReversedTooltip = (): string => {
    if (props.selectedCertificate?.status?.id === CertificateStatuses.UNCONFIRMED) {
      return "Lönduninni hefur verið breytt eða bakfært.\n\nEyða þarf tengingu og tengja hana aftur ef við á.";
    } else {
      return "Lönduninni hefur verið breytt eða bakfært.";
    }
  }

  const landingOpenTooltip = (): string => {
    return "Löndunin er ennþá í vinnslu / vantar endurvigtun.";
  }

  const usedAmountMoreThanLandedAmountTooltip = (): string => {
    return "Notað magn úr löndun er meira en heildarmagn löndunar.";
  }

  const connectedLandings: GridColDef[] = [
    {
      field: 'ship',
      headerName: 'Skip',
      valueGetter: (value, row) => row?.ship?.name + " (" + row?.ship?.registrationId + ")",
      flex: 1,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      colSpan: props.desktopView ? 1 : 42,
      renderCell: ({ row }) => (
        <span style={{ width: '95%' }}>
          {props.desktopView ? (
            <span style={{ width: '100%', display: 'flex', flexDirection: 'row', gap: '1em', paddingTop: '1em', paddingBottom: '1em' }}>
              {row?.ship?.name + " (" + row?.ship?.registrationId + ")"}
              {row?.isReversed && (
                <Tooltip title={<span style={{ whiteSpace: 'pre-line' }}>{isReversedTooltip()}</span>}>
                  <span><WarningIcon color='error' /></span>
                </Tooltip>
              )}
              {!row?.closedLanding && (
                <Tooltip title={<span style={{ whiteSpace: 'pre-line' }}>{landingOpenTooltip()}</span>}>
                  <span><WarningIcon color='info' /></span>
                </Tooltip>
              )}
              {row?.closedLanding && row?.usedAmount > row?.landedAmount && (
                <Tooltip title={<span style={{ whiteSpace: 'pre-line' }}>{usedAmountMoreThanLandedAmountTooltip()}</span>}>
                  <span><WarningIcon color='error' /></span>
                </Tooltip>
              )}
            </span>
          ) : (
            <span style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '1em', gap: '0.25em' }}>
              <span style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', padding: '1em', gap: '0.25em' }}>
                <span style={{ display: 'flex', flexDirection: 'row', gap: '0.5em' }}>
                  {row?.ship?.name + " (" + row?.ship?.registrationId + ")"}
                  {row?.isReversed && (
                    <Tooltip title={<span style={{ whiteSpace: 'pre-line' }}>{isReversedTooltip()}</span>}>
                      <span><WarningIcon color='error' /></span>
                    </Tooltip>
                  )}
                  {!row?.closedLanding && (
                    <Tooltip title={<span style={{ whiteSpace: 'pre-line' }}>{landingOpenTooltip()}</span>}>
                      <span><WarningIcon color='info' /></span>
                    </Tooltip>
                  )}
                </span>
                <span>{dayjs(new Date(row?.landingDate)).format('DD/MM/YYYY')}</span>
                <span>
                  {props?.selectedCertificate?.id !== undefined && !props?.selectedCertificate?.fromDotNetAPI
                    ? `${row?.landedAmount} kg`
                    : `${row?.usedAmount} kg / ${row?.landedAmount} kg`}
                </span>
              </span>
              {props.selectedCertificate?.status?.id === CertificateStatuses.UNCONFIRMED && props.selectedCertificate?.fromDotNetAPI && (
                <span style={{ display: 'flex', gap: '1em', alignItems: 'center' }}>
                  <Tooltip title="Breyta tengingu">
                    <span>
                      <Button
                        variant='outlined'
                        onClick={() => toggleLandingWeightModal(row?.id)}
                        color='info'
                        disabled={row?.isReversed}
                      >
                        <EditIcon />
                      </Button>
                    </span>
                  </Tooltip>
                  <Tooltip title="Eyða tengingu">
                    <span>
                      <Button
                        variant='outlined'
                        onClick={() => toggleDeleteDialog(row?.id)}
                        color='error'
                      >
                        <DeleteIcon />
                      </Button>
                    </span>
                  </Tooltip>
                </span>
              )}
            </span>
          )}
        </span>
      ),
    },
    {
      field: 'landingDate',
      headerName: 'Löndunardagsetning',
      valueGetter: (value, row) => row?.landingDate,
      renderCell: ({ row }) => {
        const landingDate = row?.landingDate;
        return (
          <span style={{ width: '100%', display: 'flex', flexDirection: 'row', gap: '1em', paddingTop: '1em', paddingBottom: '1em' }}>
            {landingDate ? dayjs(new Date(landingDate)).format('DD/MM/YYYY') : ''}
          </span>
        );
      },
      flex: 1,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
    },
    {
      field: 'usedAmount',
      headerName: (props?.selectedCertificate?.id !== undefined && !props?.selectedCertificate?.fromDotNetAPI)
        ? "Þyngd löndunar"
        : "Afurðamagn / heildarmagn löndunar",
      valueGetter: (value, row) => row?.landedAmount,
      renderCell: ({ row }) => {
        const isDotNetAPI = props?.selectedCertificate?.id !== undefined && !props?.selectedCertificate?.fromDotNetAPI;
        return (
          <span style={{ width: '100%', display: 'flex', flexDirection: 'row', gap: '1em', paddingTop: '1em', paddingBottom: '1em' }}>
            {isDotNetAPI
              ? `${row?.landedAmount} kg`
              : `${row?.usedAmount} kg / ${row?.landedAmount} kg`}
          </span>
        );
      },
      flex: 1,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
    },
    {
      field: 'delete',
      headerName: '',
      valueGetter: (value, row) => row?.id,
      renderCell: ({ row }) => (
        <span style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
          {props.selectedCertificate?.status?.id === CertificateStatuses.UNCONFIRMED && props.selectedCertificate?.fromDotNetAPI && (
            <span style={{ display: 'flex', gap: '1em' }}>
              <Tooltip title="Breyta tengingu">
                <span>
                  <Button
                    variant='outlined'
                    onClick={() => toggleLandingWeightModal(row?.id)}
                    color='info'
                    disabled={row?.isReversed}
                  >
                    <EditIcon />
                  </Button>
                </span>
              </Tooltip>
              <Tooltip title="Eyða tengingu">
                <Button
                  variant='outlined'
                  onClick={() => toggleDeleteDialog(row?.id)}
                  color='error'
                >
                  <DeleteIcon />
                </Button>
              </Tooltip>
            </span>
          )}
        </span>
      ),
      flex: 1,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
    },
  ];

  const landingSearchColumns: GridColDef[] = [
    {
      field: 'ship',
      headerName: 'Skip',
      valueGetter: (value, row) => row?.ship?.name + " (" + row?.ship?.registrationId + ")",
      flex: 1,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      colSpan: props.desktopView ? 1 : 42,
      renderCell: ({ row }) => (
        <span style={{ width: '95%' }}>
          {props.desktopView ? (
            <span style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingTop: '1em', paddingBottom: '1em' }}>
              {row?.ship?.name + " (" + row?.ship?.registrationId + ")"}
              {!row?.closedLanding && (
                <Tooltip title={<span style={{ whiteSpace: 'pre-line' }}>{landingOpenTooltip()}</span>}>
                  <span><WarningIcon color='info' /></span>
                </Tooltip>
              )}
            </span>
          ) : (
            <span style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '1em' }}>
              <span style={{ width: '80%' }}>
                {row?.ship?.name + " (" + row?.ship?.registrationId + ")"}
                {!row?.closedLanding && (
                  <Tooltip title={<span style={{ whiteSpace: 'pre-line' }}>{landingOpenTooltip()}</span>}>
                    <span><WarningIcon color='info' /></span>
                  </Tooltip>
                )}
                <br />
                {dayjs(new Date(row?.landingDate)).format('DD/MM/YYYY')}
                <br />
                {row?.landedAmount} kg
              </span>
              <Button
                variant="outlined"
                onClick={() => toggleLandingWeightModal()}
                sx={{ display: 'flex', gap: '0.5em', justifyContent: 'flex-end' }}
                disabled={filteredLandings.find(landing => landing.landingId === row?.landingId) !== undefined}
              >
                {selectedConnectedReferenceLandingId ? 'Breyta tengingu' : 'Tengja löndun'}
              </Button>
            </span>
          )}
        </span>
      ),
    },
    {
      field: 'landingDate',
      headerName: 'Löndunardagsetning',
      valueGetter: (value, row) => new Date(row?.landingDate),
      renderCell: ({ row }) => {
        const landingDate = row?.landingDate;
        return (
          <span style={{ width: '100%', display: 'flex', flexDirection: 'row', gap: '1em', paddingTop: '1em', paddingBottom: '1em' }}>
            {landingDate ? dayjs(new Date(landingDate)).format('DD/MM/YYYY') : ''}
          </span>
        );
      },
      flex: 1,
      type: 'date',
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
    },
    {
      field: 'usedAmount',
      headerName: 'Landað magn',
      valueGetter: (value, row) => row?.landedAmount,
      renderCell: ({ row }) => (
        <span style={{ width: '100%', display: 'flex', flexDirection: 'row', gap: '1em', paddingTop: '1em', paddingBottom: '1em' }}>
          {row?.landedAmount ? `${row?.landedAmount} kg` : ''}
        </span>
      ),
      flex: 1,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
    },
    {
      field: 'connect',
      headerName: '',
      valueGetter: (value, row) => row?.landedAmount,
      renderCell: ({ row }) => (
        <span style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
          <Button
            variant="outlined"
            onClick={() => toggleLandingWeightModal()}
            sx={{ display: 'flex', gap: '0.5em' }}
            disabled={filteredLandings.find(landing => landing.landingId === row?.landingId) !== undefined}
          >
            Tengja löndun
          </Button>
        </span>
      ),
      flex: 1,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
    },
  ];

  const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 300,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }));

  interface ButtonFieldProps
    extends UseDateFieldProps<Dayjs, false>,
    BaseSingleInputFieldProps<
      Dayjs | null,
      Dayjs,
      FieldSection,
      false,
      DateValidationError
    > {
    setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  }

  function ButtonField(props: ButtonFieldProps) {
    const {
      setOpen,
      id,
      disabled,
      InputProps: { ref } = {},
    } = props;

    return (
      <Tooltip title="Stilla tímabil">
        <IconButton
          id={id}
          disabled={disabled}
          ref={ref}
          onClick={() => setOpen?.((prev) => !prev)}
        >
          <CalendarMonthIcon />
          <span style={{ paddingLeft: '0.5em' }}>
            <p style={{ fontSize: '12px', margin: 0 }}>{dateFilterValue?.format('DD/MM/YYYY') + " - " + dayjs().format('DD/MM/YYYY')}</p>
          </span>
        </IconButton>
      </Tooltip>
    );
  }

  function ButtonDatePicker(
    props: Omit<DatePickerProps<Dayjs>, 'open' | 'onOpen' | 'onClose'>,
  ) {
    const [open, setOpen] = React.useState(false);
    return (
      <DatePicker
        slots={{ field: ButtonField, ...props.slots }}
        slotProps={{ field: { setOpen } as any }}
        {...props}
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        label="Dagsetning frá"
      />
    );
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer sx={{ display: 'flex', justifyContent: 'end' }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={['DatePicker']}>
            <ButtonDatePicker
              value={dateFilterValue}
              onChange={(newValue) => {
                setDateFilterValue(newValue);
                handleShipChange(selectedShip ? selectedShip : null, newValue?.toString() ? newValue.toString() : '');
              }}
            />
          </DemoContainer>
        </LocalizationProvider>
      </GridToolbarContainer>
    );
  }

  return (
    <LandingModalWrapper>
      <Modal
        open={props.landingModalOpen}
        onClose={() => props.toggleLandingModal()}
        style={{
          zIndex: 1
        }}
      >
        <LandingBox>
          <AddLandingTitle>
            <span>
              Tengdar landanir
              <HtmlTooltip
                title={
                  <React.Fragment>
                    <Typography color="inherit">Leiðbeiningar</Typography>
                    <br></br>
                    <React.Fragment>
                      <p style={{ margin: 0 }}>Velja skal þær landanir sem notaðar voru við framleiðslu á neðangreindri vöru.</p>
                      <br></br>
                      <p style={{ marginTop: 0 }}>Afurðamagn gefur til kynna hversu mikið magn úr lönduninni var notað við framleiðslu vörunnar.</p>
                    </React.Fragment>
                  </React.Fragment>
                }
              >
                <Button><HelpIcon /></Button>
              </HtmlTooltip>
            </span>

            <HtmlTooltip
              title={
                <React.Fragment>
                  <Typography color="inherit">Útskýring</Typography>
                  <React.Fragment>
                    <React.Fragment>
                      <p style={{ display: 'flex', alignItems: 'center', gap: '0.5em' }}>
                        Notað magn úr löndunum
                        <EastIcon sx={{ gap: '1em', display: 'flex' }} />
                        Þyngd útfluttrar vöru
                      </p>
                    </React.Fragment>
                  </React.Fragment>
                </React.Fragment>
              }
            >
              <Chip
                variant='outlined'
                color='primary'
                label={
                  <p style={{ display: 'flex', alignItems: 'center', gap: '0.5em' }}>
                    {filteredLandings.map(landing => landing.usedAmount).reduce((a, b) => a + b, 0)} kg
                    <EastIcon />
                    {props?.selectedProduct?.nettoWeight}
                    {props?.selectedProduct?.weightUnit?.name}
                  </p>
                }
              />
            </HtmlTooltip>
          </AddLandingTitle>

          {props.desktopView && (
            <RowContainer>
              <Card>
                <CardLabel>Fisktegund</CardLabel>
                <CardData>
                  {props.selectedProduct?.fishType?.name}
                  <SetMealIcon />
                </CardData>
              </Card>

              <Card>
                <CardLabel>Lýsing</CardLabel>
                <CardData>
                  {props.selectedProduct?.fishType?.description}
                  <DescriptionIcon />
                </CardData>
              </Card>

              <Card>
                <CardLabel>Tollskrárnúmer</CardLabel>
                <CardData>
                  {props.selectedProduct?.tarriffCode?.code}
                  <NumbersIcon />
                </CardData>
              </Card>

              <Card>
                <CardLabel>Þyngd</CardLabel>
                <CardData>
                  {props.selectedProduct?.nettoWeight} {props.selectedProduct?.weightUnit.name}
                  <ScaleIcon />
                </CardData>
              </Card>

              <Card>
                <CardLabel>Vinnsla</CardLabel>
                <CardData>
                  {props.selectedProduct?.processType?.name}
                  <SignLanguage />
                </CardData>
              </Card>

              <Card>
                <CardLabel>Pakkningar</CardLabel>
                <CardData>
                  {props.selectedProduct?.packageAmount} {props.selectedProduct?.packagingType?.name}
                  <TakeoutDiningIcon />
                </CardData>
              </Card>
            </RowContainer>
          )}

          {props.desktopView && <Divider variant="middle" sx={{ marginBottom: '0.5em' }} />}

          <TabContext value={tabValue}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleTabChange}>
                {(props.selectedCertificate?.status?.id === CertificateStatuses.UNCONFIRMED && props.selectedCertificate?.fromDotNetAPI === true) &&
                  <Tab icon={<SearchIcon />} label="Landanaleit" value="1" disabled={props.selectedCertificate?.status?.id !== CertificateStatuses.UNCONFIRMED} />
                }
                <Tab icon={<Badge color="primary" badgeContent={filteredLandings.length} ><VerifiedUserIcon /></Badge>} label={"Tengdar landanir"} value="2" />
              </TabList>
            </Box>
            <TabPanel value="1" sx={{ padding: '0' }}>
              <LandingWorkspace>
                {props.selectedCertificate?.status?.id === CertificateStatuses.UNCONFIRMED &&
                  <LandingDatagridContainer>
                    <InputRow style={{ flexDirection: props.desktopView ? 'row' : 'column' }}>
                      <Autocomplete
                        options={ships}
                        getOptionLabel={(option) => option.name + " (" + option.registrationId + ")"}
                        loading={loadingShips}
                        value={checked ? null : selectedShip ? selectedShip : null}
                        onFocus={() => setChecked(false)}
                        noOptionsText='Engar niðurstöður'
                        onChange={(e, value) => handleShipChange(value, dateFilterValue?.toString() ? dateFilterValue?.toString() : '')}
                        sx={{ width: connectedReferenceLandings.length === 0 || !props.desktopView ? '100%' : '60%' }}
                        renderInput={(params) =>
                          <TextField
                            label="Skip"
                            color="primary"
                            onChange={e => handleShipSearch(e.target.value)}
                            {...params}
                            InputProps={{
                              ...params.InputProps,
                              required: true,
                              startAdornment: (
                                <InputAdornment position='start'>
                                  <DirectionsBoatFilledIcon />
                                </InputAdornment>
                              ),
                              endAdornment: (
                                <React.Fragment>
                                  {loadingShips ? <CircularProgress color="inherit" size={20} /> : null}
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                            }}
                            required
                            autoFocus
                          />}
                      />

                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          value={singleDateFilterValue}
                          onChange={(date) => setSingleDateFilterValue(date)}
                          format="DD/MM/YYYY"
                          slotProps={{
                            actionBar: {
                              actions: ['clear']
                            },
                          }}
                          localeText={{
                            clearButtonLabel: 'Hreinsa',
                          }}
                        />
                      </LocalizationProvider>

                      {connectedReferenceLandings.length >= 1 &&
                        <Stack direction="row" spacing={1} alignItems="center">
                          <Switch
                            checked={checked}
                            onChange={handleSwitchChange}
                            inputProps={{ 'aria-label': 'ant design' }}
                          />
                          <Typography>Tengdar öðrum vörum</Typography>
                        </Stack>
                      }
                    </InputRow>

                    <DataGrid
                      rows={checked ? connectedReferenceLandings.filter((landing) => {
                        if (singleDateFilterValue) {
                          const selectedDate = singleDateFilterValue.toDate();
                          const landingDate = new Date(landing.landingDate);
                          return landingDate.toDateString() === selectedDate.toDateString();
                        }
                        return connectedReferenceLandings;
                      }) : referenceLandings.filter((landing) => {
                        if (singleDateFilterValue) {
                          const selectedDate = singleDateFilterValue.toDate();
                          const landingDate = new Date(landing.landingDate);
                          return landingDate.toDateString() === selectedDate.toDateString();
                        }
                        return referenceLandings;
                      })}
                      columns={landingSearchColumns}
                      getRowId={(row) => row.landingId.toString()}
                      sx={{
                        maxHeight: '30em',
                        minHeight: '30em',
                        '& .MuiDataGrid-virtualScroller': { overflowX: 'hidden' },
                        "& .MuiDataGrid-columnSeparator": {
                          display: 'none',
                        }
                      }}
                      slots={{
                        noRowsOverlay: () => (
                          <Stack height="90%" alignItems="center" justifyContent="center" >
                            {loadingLandings ? <CircularProgress /> : referenceLandings.length ? '' : checked ? 'Engar landanir tengdar öðrum vörum fundust fyrir fisktegund.' : 'Engar landanir fundust fyrir skip og fisktegund.'}
                          </Stack>
                        ),
                        noResultsOverlay: () => (
                          <Stack height="90%" alignItems="center" justifyContent="center">
                            {loadingLandings ? 'Leitar...' : 'Engar landanir fundust.'}
                          </Stack>
                        ),
                        toolbar: CustomToolbar,
                      }}
                      onRowClick={(row) => handleReferenceLandingChange(row.row)}
                      localeText={{
                        toolbarFilters: "Löndunardagsetning",
                        toolbarFiltersTooltipHide: "Fela síur",
                        toolbarFiltersTooltipShow: "Sýna síur",
                        filterOperatorIs: "Er",
                      }}
                      columnHeaderHeight={0}
                      getRowHeight={() => 'auto'}
                      slotProps={{
                        filterPanel: {
                          logicOperators: [GridLogicOperator.And],
                          columnsSort: 'asc',
                          filterFormProps: {
                            logicOperatorInputProps: {
                              variant: 'outlined',
                              size: 'small',
                            },
                            columnInputProps: {
                              variant: 'outlined',
                              size: 'small',
                              sx: { mt: 'auto' },
                            },
                            operatorInputProps: {
                              variant: 'outlined',
                              size: 'small',
                              sx: { mt: 'auto' },
                            },
                            valueInputProps: {
                              InputComponentProps: {
                                variant: 'outlined',
                                size: 'small',
                                label: ''
                              },
                            },
                            deleteIconProps: {
                              sx: {
                                '& .MuiSvgIcon-root': { color: '#d32f2f' },
                              },
                            },
                          },
                          sx: {
                            '& .MuiDataGrid-filterForm': { p: 2 },
                            '& .MuiDataGrid-filterForm:nth-of-type(even)': {
                              backgroundColor: (theme: Theme) =>
                                theme.palette.mode === 'dark' ? '#444' : '#f5f5f5',
                            },
                            '& .MuiDataGrid-filterFormLogicOperatorInput': { mr: 2 },
                            '& .MuiDataGrid-filterFormColumnInput': { mr: 2, width: 150 },
                            '& .MuiDataGrid-filterFormOperatorInput': { mr: 2 },
                            '& .MuiDataGrid-filterFormValueInput': { width: 200 },
                            '.MuiNativeSelect-select option': {
                              fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                              fontWeight: 400,
                              fontSize: '1rem',
                              lineHeight: '1.4375em',
                              letterSpacing: '0.00938em',
                              color: 'rgba(0, 0, 0, 0.87)',
                              boxSizing: 'border-box',
                              position: 'relative',
                              cursor: 'text',
                              display: 'inline-flex',
                              alignItems: 'center',
                              borderRadius: '4px',
                            },
                            maxWidth: "100vw"
                          },
                        },
                      }}
                    />
                  </LandingDatagridContainer>
                }
              </LandingWorkspace>
            </TabPanel>

            <TabPanel value="2" sx={{ padding: '0' }}>
              <LandingWorkspace>
                <LandingDatagridContainer>
                  <DataGrid
                    rows={filteredLandings}
                    columns={connectedLandings}
                    sx={{
                      maxHeight: '35em',
                      minHeight: '35em',
                      '& .MuiDataGrid-virtualScroller': { overflowX: 'hidden' },
                    }}
                    columnHeaderHeight={50}
                    slots={{
                      noRowsOverlay: () => (
                        <Stack height="90%" alignItems="center" justifyContent="center">
                          {referenceLandings.length ? '' : 'Engar landanir hafa verið valdar.'}
                        </Stack>
                      )
                    }}
                    getRowHeight={() => 'auto'}
                  />
                </LandingDatagridContainer>
              </LandingWorkspace>
            </TabPanel>
          </TabContext>

          <Button
            variant="outlined"
            onClick={() => props.toggleLandingModal()}
            sx={{ display: 'flex', gap: '0.5em', width: '10em', alignItems: 'end' }}
          >
            Til baka
          </Button>

        </LandingBox>
      </Modal>

      <Modal
        open={landingWeightModalOpen}
        onClose={() => toggleLandingWeightModal()}
      >
        <LandingWeightInputBox>
          <AddLandingTitle>
            <span style={{ display: 'flex', gap: '0.5em', justifyItems: 'center' }}>
              {selectedConnectedReferenceLandingId ? 'Breyta tengingu' : 'Tengja löndun'}
              {!selectedReferenceLanding?.closedLanding &&
                <Tooltip title={<span style={{ whiteSpace: 'pre-line' }}>{landingOpenTooltip()}</span>}>
                  <span>
                    <WarningIcon color='info' />
                  </span>
                </Tooltip>
              }
            </span>
            <HtmlTooltip
              title={
                <React.Fragment>
                  <Typography color="inherit">Leiðbeiningar</Typography>
                  <br></br>
                  <React.Fragment>
                    <p style={{ margin: 0 }}>Velja skal þær landanir sem notaðar voru við framleiðslu á vörunni.</p>
                    <br></br>
                    <p style={{ marginTop: 0 }}>Afurðamagn gefur til kynna hversu mikið magn úr lönduninni var notað við framleiðslu vörunnar.</p>
                  </React.Fragment>
                </React.Fragment>
              }
            >
              <Button><HelpIcon /></Button>
            </HtmlTooltip>
          </AddLandingTitle>

          <Card>
            <CardData>
              {selectedReferenceLanding?.ship?.name + " (" + selectedReferenceLanding?.ship?.registrationId + ")"}
              <br></br>
              {selectedReferenceLanding?.landingDate && dayjs(new Date(selectedReferenceLanding?.landingDate)).format('DD/MM/YYYY')}
              <br></br>
              {props.selectedProduct?.fishType?.name} {selectedReferenceLanding?.landedAmount} kg
            </CardData>
          </Card>

          <TextField
            type="number"
            label={(props?.selectedCertificate?.id !== undefined && props?.selectedCertificate?.fromDotNetAPI === false) ? "Þyngd löndunar" : "Afurðamagn / heildarmagn löndunar"}
            disabled={selectedReferenceLanding?.landedAmount === undefined}
            onChange={(e) => {
              handleChange('netWeightFromLanding', e.target.value)
              setErrorModel({
                ...errorModel,
                netWeightFromLanding: {
                  error: Number(e.target.value) < 0 || Number(e.target.value) > Number(multipleProductsConnectedToLanding ? amountLeftSelectedReferenceLanding : selectedReferenceLanding?.landedAmount) ? true : false,
                  message: Number(e.target.value) < 0 || Number(e.target.value) > Number(multipleProductsConnectedToLanding ? amountLeftSelectedReferenceLanding : selectedReferenceLanding?.landedAmount) ? 'Nettóþyng má ekki vera minni en núll og má ekki vera meiri en heildarmagn löndunar.' : '',
                }
              });
            }}
            defaultValue={filteredLandings.find(referenceLanding => referenceLanding.id === selectedConnectedReferenceLandingId)?.usedAmount}
            InputProps={{
              startAdornment: <ScaleIcon sx={{ paddingRight: '0.5em' }} />,
              endAdornment: <InputAdornment position="start"> /&nbsp;<p style={{ textDecoration: multipleProductsConnectedToLanding ? 'line-through' : '' }}>&nbsp;{selectedReferenceLanding?.landedAmount}kg</p>&nbsp;{multipleProductsConnectedToLanding ? amountLeftSelectedReferenceLanding + 'kg' : ''}</InputAdornment>,
              inputProps: { min: 0, max: selectedReferenceLanding?.landedAmount },
            }}
            required
            autoFocus
            error={errorModel.netWeightFromLanding?.error}
            helperText={errorModel.netWeightFromLanding?.message}
            autoComplete='off'
          />
          <Button
            variant="outlined"
            onClick={selectedConnectedReferenceLandingId ? handleEditLanding : handleSubmitLanding}
            sx={{ display: 'flex', gap: '0.5em' }}
            disabled={errorModel.netWeightFromLanding?.error || !landingFormData?.netWeightFromLanding}
          >
            {selectedConnectedReferenceLandingId ? 'Breyta tengingu' : 'Tengja löndun'}
          </Button>
        </LandingWeightInputBox>
      </Modal>

      <Dialog
        open={deleteDialogOpen}
        onClose={() => toggleDeleteDialog()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Eyða löndunartengingu"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Ertu viss um að þú viljir fjarlægja löndunartengingu?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => toggleDeleteDialog()}>Hætta við</Button>
          <Button onClick={() => handleDeleteLanding(landingId)} autoFocus>
            Samþykkja
          </Button>
        </DialogActions>
      </Dialog>
    </LandingModalWrapper>
  );

}

export default AddLandingModal;
