import styled from 'styled-components';

export const LandingModalWrapper = styled.div`
`;

export const LandingBox = styled.div`
  border-radius: 1em;
  padding: 1em;
  display: flex;
  flex-direction: column;
  p: 4;

  background-color: white;
  gap: 1em;
  position: fixed;
  top: 5%;
  z-index: 100;
  overflow: auto;

  @media only screen and (min-width: 0px) {
    /* For mobile: */
    width: 90%;
    height: 85%;
  }

  @media only screen and (min-width: 1200px) {
    /* For desktop: */
    width: 70%;
    height: 85%;
    left: 15%;
  }
`;

export const AddLandingTitle = styled.h2`
  font-size: 1.3em;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  gap: 0.4em;
  line-height: 1.3em;
  align-items: center;
  margin: 0;
`;

export const ListItem = styled.div`
  padding: 1em;
  flex: 1;
`;

export const CardData = styled.span`
  color: #1e2022;
  font-size: 0.9rem;
  line-height: 1.5;
  margin: 0;
  display: flex;
  justify-content: space-between;
  font-size: 0.8em;
`;

/*!
 * Bootstrap  v5.2.2 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
export const Card = styled.div`
  box-shadow: 0 0.375rem 0.75rem rgba(140,152,164,.075);
  --bs-card-spacer-y: 1.3125rem;
  --bs-card-spacer-x: 1.3125rem;
  --bs-card-title-spacer-y: 0.25rem;
  --bs-card-border-width: 0.0625rem;
  --bs-card-border-color: rgba(231, 234, 243, 0.7);
  --bs-card-border-radius: 0.75rem;
  --bs-card-box-shadow: 0rem 0.375rem 0.75rem rgba(140, 152, 164, 0.075);
  --bs-card-inner-border-radius: 0.6875rem;
  --bs-card-cap-padding-y: 1.3125rem;
  --bs-card-cap-padding-x: 1.3125rem;
  --bs-card-cap-bg: transparent;
  --bs-card-bg: #fff;
  --bs-card-img-overlay-padding: 1.3125rem 1.3125rem;
  --bs-card-group-margin: 0.75rem;
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
  padding: 1em;
  flex: 1 1 auto;
`;

export const CardLabel = styled.div`
  text-transform: uppercase;
  color: #8c98a4;
  font-size: 0.8rem;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  padding-bottom: 0.5em;
`;

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  gap: 0.8em;
  @media only screen and (max-width: 1200px) {
    /* For mobile: */
    flex-wrap: wrap;
  }
`;

export const LandingWorkspace = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  gap: 1em;
`;

export const LandingDatagridContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
  width: 100%;
`;

export const LandingWeightInputBox = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fafafa;
  border-radius: 1em;
  p: 4;
  padding: 1em;
  display: flex;
  flex-direction: column;
  background-color: white;
  gap: 1em;

  @media only screen and (min-width: 0px) {
    /* For mobile: */
    width: 90%;
  }

  @media only screen and (min-width: 1200px) {
    /* For desktop: */
    width: 25%;
  }

`;

export const InputRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1em;
  justify-content: space-between;
`;
